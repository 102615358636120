<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card class="pa-5">
            <div class="text-h4 text-center">Add Project</div>
            <v-form ref="form"
            >
              <v-container>
                <v-row class="justify-center">
                  <v-col cols="12" lg="6">
                    <v-alert type="error" v-if="error">{{ error }}</v-alert>
                    <v-text-field
                        v-model="formData.name"
                        label="Project Name"
                        type="text"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" lg="6">
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="formData.start_date"
                            label="Project Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="formData.start_date"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" lg="3">
                    <v-text-field
                        v-model="formData.ref_person"
                        label="Ref Person"
                        type="text"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field
                        label="Ref. Person Contact No"
                        type="number"
                        v-model="formData.contact_number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" lg="6">
                    <v-text-field
                        v-model="formData.address"
                        label="Ref. Person Address"
                        type="text"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" lg="6">
                    <v-textarea
                        rows="2"
                        v-model="formData.description"
                        label="Project Description"
                        type="text"
                        required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
              <v-row class="justify-center">
                <v-col cols="12" lg="6" class="text-center">
                  <v-btn color="secondary" class="px-10 mr-5" @click="$router.push('/projects')">Veiw List</v-btn>
                  <v-btn color="primary" class="px-10" @click="createProject()" :disabled="loading">Add</v-btn>
                  <br>
                  <v-progress-circular
                      indeterminate
                      color="primary"
                      class="mt-5"
                      v-if="loading"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {create_project} from "@/apis/project"
export default {
  data() {
    return {
      formData: {
        name: "",
        start_date:'',
        ref_person: "",
        contact_number: "",
        address: "",
        description: ""
      },
      loading: false,
      error: '',
    }
  },
  methods: {
    createProject() {
      this.loading = true;
      this.error = '';
      create_project(this.formData).then(
          resp => {
            this.loading = false
            if (resp.status == 'Success') {
              this.$toastr.s(resp.message);
              this.$router.push('/projects')
              this.$refs.form.reset();
            } else {
              this.error = resp.errors;
            }
          }
      )
    }
  }
}
</script>

<style>

</style>